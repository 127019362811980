import styled, { css } from 'styled-components';
import { Button } from '@naf/button';
import { Text } from '@naf/text';
import { breakpoints, spacing } from '@naf/theme';
import { nafColor } from '@nafcore/theme';
import { BaseButtonProps } from '../../../../types/baseContainerProps';

const Heading = styled(Text)`
  margin-top: ${spacing.space32};
  margin-bottom: ${spacing.space32};
  @media (min-width: ${breakpoints.m}) {
    margin-top: ${spacing.space56};
    margin-bottom: ${spacing.space48};
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  margin: ${spacing.space8} 0 ${spacing.space40};

  @media (max-width: ${breakpoints.m}) {
    margin-top: ${spacing.space32};
  }
`;

const StyledButton = styled(Button)<BaseButtonProps>`
  margin-right: 10px;
  display: ${(props) => (props.disabled ? 'flex' : 'flex')};
`;

const Labels = styled.div`
  display: flex;
  flex-direction: row;
  order: -2;
  margin-bottom: ${spacing.space16};
`;

const HoverBlock = styled(Text)`
  display: none;
  position: absolute;
  top: -${spacing.space32};
  left: -${spacing.space4};
  z-index: 9;
  margin: 0;
  padding: 2px 8px;
  border-radius: 2px;
  background-color: ${nafColor.primary.spruce};
  color: ${nafColor.signature.white};
  width: fit-content;

  &:after {
    content: ' ';
    border: 6px solid;
    border-color: ${nafColor.primary.spruce} transparent transparent transparent;
    position: absolute;
    top: 100%;
    left: calc(50% - 2px);
    margin-left: -3px;
  }
`;

const Label = styled.div<{ $isCurrent: boolean; $totalSteps: number; $isOverflown: boolean }>`
  position: relative;
  display: flex;
  flex-grow: 0;
  width: ${(props) => (1 / props.$totalSteps) * 100}%;
  box-sizing: border-box;
  text-align: start;
  font-weight: ${(props) => (props.$isCurrent ? 600 : 'intrinsic')};
  margin-right: ${spacing.space32};
  @media (max-width: ${breakpoints.m}) {
    margin-right: ${spacing.space24};
  }
  @media (max-width: ${breakpoints.s}) {
    display: ${(props) => (props.$isCurrent ? 'flex' : 'none')};
    margin-right: 0;
    width: 100%;
  }

  &:last-child {
    margin-right: 0 !important;
  }

  > span {
    display: block;
    max-width: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: ${spacing.space32};
    @media (max-width: ${breakpoints.m}) {
      margin-right: ${spacing.space24};
    }
    @media (max-width: ${breakpoints.s}) {
      width: 100%;
    }

    ${({ $totalSteps }) =>
      $totalSteps > 5 &&
      css`
        margin-right: ${spacing.space24};
      `}
  }

  ${({ $isOverflown }) =>
    $isOverflown &&
    css`
      cursor: pointer;
      &:hover {
        ${HoverBlock} {
          display: flex;
        }
      }
    `}
`;

const ProgressBarElementsWrapper = styled.div`
  display: flex;
  order: -1;
  margin-bottom: ${spacing.space32};
  @media (min-width: ${breakpoints.m}) {
    margin-bottom: ${spacing.space48};
  }
`;

const ProgressBarElement = styled.span<{ $isActive: boolean; $totalSteps: number }>`
  width: ${(props) => (1 / props.$totalSteps) * 100}%;
  height: ${spacing.space4};
  background-color: ${(props) => (props.$isActive ? nafColor.primary.kart : nafColor.neutral.neutral2)};
  margin-right: ${spacing.space32};
  box-sizing: border-box;
  @media (max-width: ${breakpoints.m}) {
    margin-right: ${spacing.space24};
  }
  @media (max-width: ${breakpoints.s}) {
    margin-right: ${spacing.space12};
  }

  ${({ $totalSteps }) =>
    $totalSteps > 5 &&
    css`
      margin-right: ${spacing.space24};
    `}

  &:last-child {
    margin-right: 0 !important;
  }
`;

const Step = styled.div`
  margin: 20px 0;
`;

const NavigationButtons = styled.nav`
  order: 2;
`;

const Counter = styled(Text)`
  margin: 0;
  margin-bottom: ${spacing.space8};
  display: flex;
  flex-basis: 100%;
  color: ${nafColor.neutral.neutral4};
  font-weight: 400;
`;

export default {
  Heading,
  Buttons,
  HoverBlock,
  Labels,
  Label,
  ProgressBarElementsWrapper,
  ProgressBarElement,
  NavigationButtons,
  Step,
  StyledButton,
  Counter,
};
