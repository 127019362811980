import React, { FC, useEffect, useState } from 'react';
import { Text, TextVariant } from '@naf/text';
import styled from 'styled-components';
import { StepWizardChildProps } from 'react-step-wizard';
import { Spinner } from '../../../../components/spinner/Spinner';
import useSelector from '../../../../redux/typedHooks';
import { useSendGTMEventOnce } from '../../../../hooks/useSendGTMEventOnce';
import { NAFGTMEvent } from '../../../../../../types/GTM/NAFGTMEvent';
import { useGTMDataLayer } from '../../../../hooks/useGTMDataLayer';
import { funnelPayload } from '../../../../../../types/gtmFunnel';

export const Redirect: FC<Partial<StepWizardChildProps>> = ({ goToStep, currentStep }) => {
  const [tempError, setTempError] = useState<string | undefined>(undefined);
  const isCreatingOrder = useSelector((state) => state.order.isCreatingOrder);
  const paymentOrderUrl = useSelector((state) => state.order.orderDetails?.paymentOrderUrl);
  const isCreated = useSelector((state) => state.order.isCreated);
  const serverError = useSelector((state) => state.order.validation?.validationResults);

  const dataLayer = useGTMDataLayer();

  // Hvis feilen er ukjent, dvs null i validationDetails, vis en feilmelding i 3 sekunder og gå tilbake
  useEffect(() => {
    if (goToStep && dataLayer && currentStep) {
      if (serverError === null) {
        setTempError('Det oppstod en ukjent feil, prøv igjen om litt');
        setTimeout(() => {
          setTempError(undefined);
          dataLayer?.push({
            event: NAFGTMEvent.checkoutAction,
            checkout_step: currentStep,
            checkout_action: 'Ukjent valideringsfeil fra server',
          });
          goToStep(2);
        }, 3000);
      }
      if (serverError) {
        dataLayer?.push({
          event: NAFGTMEvent.checkoutAction,
          checkout_step: currentStep,
          checkout_action: 'Kjent valideringsfeil fra server',
        });
        goToStep(2);
      }
    }
  }, [serverError, goToStep, dataLayer, currentStep]);

  useEffect(() => {
    if (typeof window !== 'undefined' && !!paymentOrderUrl) {
      window.location.href = `${paymentOrderUrl}`;
    }
  }, [paymentOrderUrl]);

  useSendGTMEventOnce(funnelPayload('bli-medlem', 3), []);

  return (
    <>
      {isCreatingOrder && <AnimatedText variant={TextVariant.Header3}>Sender ordren</AnimatedText>}
      {isCreated && (
        <AnimatedText variant={TextVariant.Header3}>Ordren er sendt, du blir nå videresendt til betaling</AnimatedText>
      )}
      {tempError && <AnimatedText variant={TextVariant.Header3}>{tempError}</AnimatedText>}
      <Spinner />
    </>
  );
};

const AnimatedText = styled(Text)`
  &:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4, end) 900ms infinite;
    animation: ellipsis steps(4, end) 900ms infinite;
    content: '\\2026'; /* ascii code for the ellipsis character */
    width: 0;
  }

  @keyframes ellipsis {
    to {
      width: 25px;
    }
  }

  @-webkit-keyframes ellipsis {
    to {
      width: 25px;
    }
  }
`;
