import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import type { MyProfileForm } from '@dtp/membership-service-types';
import useSelector from '../redux/typedHooks';
import { TokenType } from '../../../types/tokenType';

interface ReturnType {
  profile: MyProfileForm | undefined;
  isLoading: boolean;
  error: boolean;
}

export const useFetchProfile = ({ token }: TokenType): ReturnType => {
  const [profile, setProfile] = useState<MyProfileForm>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const { apimBaseUrl, apimMembershipApi, apimContentHub } = useSelector((state) => state.application);

  const getProfile = useCallback(async () => {
    if (token) {
      try {
        const res = await axios.get<MyProfileForm>(`${apimBaseUrl}/${apimMembershipApi}/profile`, {
          headers: { Authorization: `Bearer ${token}`, 'Ocp-Apim-Subscription-Key': apimContentHub },
        });
        setIsLoading(false);
        setProfile(res.data);
        setError(false);
      } catch (e) {
        setIsLoading(false);
        setError(true);
      }
    }
  }, [token, apimBaseUrl, apimContentHub, apimMembershipApi]);

  useEffect(() => {
    getProfile().then();
    setIsLoading(true);

    return () => {};
  }, [getProfile]);

  return { profile, isLoading, error };
};
