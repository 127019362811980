import React from 'react';
import { UnpackNestedValue, useFormContext } from 'react-hook-form';
import S from '../../../styles/becomeAMember/StyledBecomeAMember';
import { useGTMDataLayer } from '../../../hooks/useGTMDataLayer';
import { NAFGTMEvent } from '../../../../../types/GTM/NAFGTMEvent';

export type WizardNavigationProps = {
  currentStep: number;
  totalSteps: number;
  previousStep: () => void;
  nextStep: () => void;
  isValid: boolean;
  onSubmit: (e: UnpackNestedValue<any>) => void;
  goToStep?: (step: number) => void;
  onGoback?: (e: UnpackNestedValue<any>) => void;
  getValues?: () => UnpackNestedValue<any>;
};

export const WizardNavigation = ({
  currentStep,
  totalSteps,
  previousStep,
  nextStep,
  isValid,
  onSubmit,
  onGoback,
  getValues,
}: Partial<WizardNavigationProps>) => {
  const dataLayer = useGTMDataLayer();
  const currentForm = useFormContext();
  const { handleSubmit } = currentForm || {};

  const onNextHandler = (e) => {
    if (onSubmit) onSubmit(e);
    window.scrollTo(0, 0);
    if (nextStep) {
      if (currentStep) {
        dataLayer?.push({
          event: NAFGTMEvent.checkoutAction,
          checkout_step: currentStep,
          checkout_action: currentStep && totalSteps && currentStep !== totalSteps - 1 ? 'Neste' : 'Gå til betaling',
        });
      }
      nextStep();
    }
  };

  const onNext = handleSubmit ? handleSubmit(onNextHandler) : onNextHandler;

  return (
    <S.NavigationButtons>
      <S.Buttons>
        {currentStep !== 1 && (
          <S.StyledButton
            variant="outline"
            onClick={() => {
              if (getValues && onGoback && previousStep) {
                const values = getValues();
                onGoback(values);
                previousStep();
              } else if (previousStep) previousStep();
              if (currentStep) {
                dataLayer?.push({
                  event: NAFGTMEvent.checkoutAction,
                  checkout_step: currentStep,
                  checkout_action: 'Tilbake',
                });
              }
            }}
            disabled={currentStep === 1}
          >
            Tilbake
          </S.StyledButton>
        )}
        <S.StyledButton onClick={onNext} disabled={currentStep === totalSteps || !isValid}>
          {currentStep && totalSteps && currentStep !== totalSteps - 1 ? 'Neste' : 'Gå til betaling'}
        </S.StyledButton>
      </S.Buttons>
    </S.NavigationButtons>
  );
};
